import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

const GeneralitiesDerivation = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Généralités Derivation" />
        <Typography
          variant="h4"
          color="inherit"
          style={{ margin: "20px 0px 0px" }}
        >
          Dérivation biliopancréatique
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          style={{ margin: "8px 0px 20px" }}
        >
          Technique restrictive et malabsorptive
        </Typography>
        <Image alt="Derivation" filename="derivation.png" />
        <Typography
          variant="caption"
          color="inherit"
          style={{ textAlign: "center", display: "block", marginBottom: 20 }}
        >
          <a href="https://www.has-sante.fr/upload/docs/application/pdf/2009-09/fiche_technique_derivation_biliopanceeatique_080909.pdf">
            <i>
              https://www.has-sante.fr/upload/docs/application/pdf/2009-09/fiche_technique_derivation_biliopanceeatique_080909.pdf
            </i>
          </a>
        </Typography>
        <Typography variant="body1">
          Cette technique permet de limiter la quantité d’aliments ingérés et
          l’assimilation de ces aliments par l’intestin. La taille de l’estomac
          est réduite par gastrectomie, et l’intestin grêle divisé en deux
          parties au niveau du jéjunum. L’estomac est raccordé à l’anse reliée
          au colon (= anse alimentaire). L’anse comprenant le duodénum et la
          partie proximale du jéjunum est raccordée à l’iléon (= anse
          biliopancréatique), et va servir à transporter les sécrétions
          digestives du foie et du pancréas. L’essentiel des aliments passe donc
          directement dans le colon sans être absorbé.
        </Typography>
        <Typography variant="body1" style={{ marginTop: 12 }}>
          Cette technique est réservée aux patients avec IMC ≥ 50 kg/m2, et/ou
          après échec d’une autre technique.
        </Typography>
        <div style={{ width: "100%", marginTop: 20, textAlign: "center" }}>
          <Button href="/generalities">Revenir aux Généralités</Button>
        </div>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default GeneralitiesDerivation
